import React from 'react';
import PropTypes from 'prop-types';
import VideoItem from '../../molecules/video-item/VideoItem';
import { Wrapper } from '../../utils/components';
import { getCommonOrganismData } from '../../utils/functions';
import Title from '../../molecules/title/title';
import Slider from 'react-slick';
import './Video.scss';
import classNames from 'classnames';

import { useWithMobileResizeListener } from '../../utils/hooks/useWithMobileResizeListener';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import withPreview from '../../utils/with-preview';

const Video = ({ data, considerMenuSpace }) => {
  const videos = data?.videoId || [];

  const isMobile = useWithMobileResizeListener(768);
  const disableSliderUnder = data?.disableSliderUnder?.value || false;
  const disableSliderOver = data?.disableSliderOver?.value || false;
  const hideDots = data?.hideDots?.value || false;

  const videoItems = videos.map((videoItem, i) => {
    return (
      <VideoItem
        key={i}
        videoId={videoItem.value}
        videoSite={videoItem?.content?.videoProvider?.value[0]}
        image={videoItem?.content?.videoImage?.node?.gatsbyImageData}
      />
    );
  });

  const settings = {
    infinite: false, // If is set to true, if elements < sideToShow it generetes clone tile
    dots: !hideDots,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    slide: '',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: () => <div className='slick-dots__custom-dot'></div>,
  };

  const {
    anchorLabel,
    backgroundColor,
    htmlTagWrapper,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const renderVideoItems = () => {
    if (videoItems.length <= 2) {
      return <div className='video__items'>{videoItems}</div>;
    }

    return (
      <Slider className='carousel' {...settings}>
        {videoItems}
      </Slider>
    );
  };

  const renderMobile = () => {
    if (disableSliderUnder) {
      return <div className='video__items no-carousel'>{videoItems}</div>;
    }
    return renderVideoItems();
  };

  const renderDesktop = () => {
    if (disableSliderOver) {
      return <div className='video__items no-carousel'>{videoItems}</div>;
    }
    return renderVideoItems();
  };

  const renderSingleVideo = () => {
    return (
      <div className='video__single'>
        <VideoItem
          videoId={videos[0].value}
          videoSite={videos[0]?.content?.videoProvider?.value[0]}
          image={videos[0]?.content?.videoImage?.node?.gatsbyImageData}
        />
      </div>
    );
  };

  return (
    <Wrapper
      tag={htmlTagWrapper}
      className={classNames(`video wrapper ${marginTop}`, {
        'video--colored-background': backgroundColor !== 'transparent',
      })}
      data-module-name='video'
      data-module-code='C-21'
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
        >
          {title}
        </Title>
      )}
      {videos.length === 1
        ? renderSingleVideo()
        : isMobile
        ? renderMobile()
        : renderDesktop()}
    </Wrapper>
  );
};

Video.propTypes = {
  data: PropTypes.object,
};

export default withPreview(Video);
